import React from "react"

const Module = props => (
  /* Module SVG Code will go here (the icon with the index finger pointing/clicking on a button || Need SVG from creative) */
  <svg viewBox="0 0 28 28" {...props}>
    <path d="M23.917 10.5h-1.75V8.167C22.167 3.664 18.504 0 14 0S5.833 3.664 5.833 8.167V10.5h-1.75a.583.583 0 00-.583.583v14.583a2.335 2.335 0 002.333 2.333h16.333a2.335 2.335 0 002.333-2.333V11.083a.583.583 0 00-.583-.583zm-8.17 12.186a.585.585 0 01-.58.647h-2.333c-.166 0-.324-.071-.435-.194s-.164-.289-.145-.453l.368-3.309a2.307 2.307 0 01-.955-1.876c0-1.287 1.046-2.333 2.333-2.333s2.333 1.046 2.333 2.333c0 .754-.357 1.442-.955 1.876l.368 3.309zm2.92-12.186H9.334V8.167c0-2.573 2.093-4.667 4.667-4.667s4.667 2.093 4.667 4.667V10.5z" />
  </svg>
)

export default Module
