const gaId = "UA-167914154-1"

module.exports = {
  siteMetadata: {
    siteUrl: `https://amazingmeselfesteem.com`,
    partner: `Unilever`,
    title: `Amazing Me`,
    description: `Each of us is unique, beautiful and worth celebrating! Explore classroom and family confidence-building resources for your elementary students:`,
    gaId: gaId,
    oauthClientId: "DO WE NEED THIS?",
    oauthAuthEndpoint: "DO WE NEED THIS?",
    author: "@discoveryed",
    twitterText:
      "Each of us is unique, beautiful and worth celebrating! Explore classroom and family confidence-building resources for your elementary students:",
    emailSubject: "Amazing Me",
    emailBody: `The Dove Self-Esteem Project and Discovery Education have teamed up to deliver the best in social and emotional learning (SEL), health, and confidence-building resources for educators teaching grades 4-5. We believe a confident student makes a happy, healthy, and successful student, which is why we have created a suite of classroom and family resources focused on building self-esteem, promoting positive body image, and countering bullying. To learn more, visit: www.amazingmeselfesteem.com`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/images/theme/img-unilever-favicon.jpg`,
      },
    },
    `gatsby-plugin-sass`,
    {
      resolve: "@discoveryed/de-analytics",
      options: {
        partnershipId: `59c02648-edee-426b-9fd5-71a40f159486`,
      },
    },

    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
