import { Container, Row, Column } from "../Grid"
// import Share from "../Share"
// import Subscribe from "../Subscribe"
import React from "react"
// import { Link } from "gatsby"
// import deLogo from "../../images/global/img-de-logo.png"

import "./styles.scss"

const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <footer className="footer">
      {/*<Container>*/}
      {/*  <div className="footer__logos">*/}
      {/*    <div className="footer__logos-sponsors">*/}
      {/*      <a*/}
      {/*        href="https://catch.org"*/}
      {/*        target="_blank"*/}
      {/*        rel="noopener noreferrer"*/}
      {/*        className="cvs-pharmacy"*/}
      {/*      >*/}
      {/*        <img src={CVSLogo} alt="CVS Pharamacy" />*/}
      {/*      </a>*/}
      {/*      <a*/}
      {/*        href="https://www.discoveryeducation.com"*/}
      {/*        target="_blank"*/}
      {/*        rel="noopener noreferrer"*/}
      {/*        className="discovery"*/}
      {/*      >*/}
      {/*        <img src={DiscoveryLogo} alt="Discovery Education" />*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    <p>*/}
      {/*      The Dove Self-Esteem Project and Discovery Education have joined forces to bring Amazing Me to elementary school educators and families nationwide. We believe that students who are confident lead healthier, happier lives. Access our resources to help students realize their full potential and achieve success in school and beyond.*/}
      {/*    </p>*/}
      {/*    <p>*/}
      {/*      <Link to="/about/">Learn more about us</Link>*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div className="footer__links">*/}
      {/*    <div className="signup">*/}
      {/*      <Subscribe*/}
      {/*        trigger={*/}
      {/*          <button className="global__button red">*/}
      {/*            Sign Up for Updates*/}
      {/*          </button>*/}
      {/*        }*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    <div className="footer__share">*/}
      {/*      <Share />*/}
      {/*    </div>*/}
      {/*    <div className="footer__hastag">*/}
      {/*      <a*/}
      {/*        href="https://twitter.com/hashtag/doseofknowledge?f=tweets&amp;vertical=default&amp;src=hash"*/}
      {/*        target="_blank"*/}
      {/*        rel="noopener noreferrer"*/}
      {/*      >*/}
      {/*        #DoseofKnowledge*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Container>*/}
      <div className="footer__copyright">
        <Container>
          <Row>
            <Column span={8}>
              <div className="footer__copyright-text">
                <p>&copy; {year}. All rights are reserved.</p>
              </div>
            </Column>
            <Column span={4}>
              <div className="footer__copyright-links">
                <a href="/" className="cookie_settings">
                  Cookie Settings
                </a>{" "}
                |
                <a
                  href="https://www.discoveryeducation.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>{" "}
                |
                <a
                  href="https://www.discoveryeducation.com/terms-of-use/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>{" "}
                {/*|*/}
                {/*<a*/}
                {/*  href="mailto:apowers@discoveryed.com"*/}
                {/*  target="_blank"*/}
                {/*  rel="noopener noreferrer"*/}
                {/*>*/}
                {/*  Contact Us*/}
                {/*</a>*/}
              </div>
            </Column>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

Footer.defaultProps = {}
Footer.propTypes = {}
export default Footer
