import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import useImageQuery from "../../hooks/useImageQuery"
import "./styles.scss"

const Video = ({
  src,
  guidSrc,
  poster,
  autoplay,
  youtube_id,
  vimeo_id,
  title,
  className,
  ...other
}) => {
  const [ready, setReady] = useState(false)
  const [hostname, setHostname] = useState("")
  const { getFinalImagePath, getFinalImagePathByFilename } = useImageQuery()

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          gaId
        }
      }
    }
  `)

  // This will run once after the initial render.
  useEffect(() => {
    setHostname(
      window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")
    )
    setReady(true)
  }, [])

  const getUrl = () => {
    if (vimeo_id) {
      return `https://vimeo.com/${vimeo_id}`
    }

    if (youtube_id) {
      return `https://www.youtube-nocookie.com/embed/${youtube_id}`
    }

    return null
  }

  const getQueryString = () => {
    let qs = `videoSrc=${src}`

    if (poster) {
      if (poster.startsWith("https://") || poster.startsWith("http://")) {
        qs += `&posterSrc=${poster}`
      } else {
        // If we reach here, this must be a local path
        let imagePath = getFinalImagePath(poster)
        if (!imagePath) {
          imagePath = getFinalImagePathByFilename(poster)
        }
        qs += `&posterSrc=${encodeURI(hostname + imagePath)}`
      }
    }
    if (site.siteMetadata.gaId) {
      qs += `&gaId=${site.siteMetadata.gaId}`
    }

    return qs
  }

  const renderVideo = () => {
    return guidSrc || src ? (
      <>
        {ready && (
          <div className={classNames("video", className)} {...other}>
            <iframe
              src={
                src
                  ? `https://embed.discoveryeducation.com?${getQueryString()}`
                  : `https://app.discoveryeducation.com/learn/embedded-player/${guidSrc}?utm_source=microsite&utm_medium=embed`
              }
              title={title}
              poster={poster}
              width="560"
              height="349"
              frameBorder="0"
              scrolling="no"
              autoPlay={autoplay}
              allowFullScreen
            ></iframe>
          </div>
        )}
      </>
    ) : (
      <div className="video__player">
        <ReactPlayer
          url={getUrl()}
          width="100%"
          height="100%"
          controls
          playing
        />
      </div>
    )
  }

  return renderVideo()
}

Video.defaultProps = {
  src: null,
  poster: null,
  autoplay: false,
  className: null,
}

Video.propTypes = {
  /** The URL of the video src. */
  src: PropTypes.string.isRequired,
  /** The GUID for the video. */
  guidSrc: PropTypes.string,
  /** The title of the video */
  title: PropTypes.string.isRequired,
  /** The URL for a poster image */
  poster: PropTypes.string,
  /** Toggle autoplay */
  autoplay: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
}

export default Video
