import PropTypes from "prop-types"
import classNames from "classnames"
import React, { useState } from "react"
import Icon from "../Icon"
import { useStaticQuery, graphql } from "gatsby"
import "./styles.scss"

const ShareDropdown = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            twitterText
            emailSubject
            emailBody
          }
        }
      }
    `
  )

  const [dropDownOpen, setDropDownState] = useState(false)

  const handleClick = () => setDropDownState(state => !state)

  return (
    <div className="share__dropdown">
      <button onClick={handleClick} className="share__title">
        Share
      </button>
      <ul className={classNames(dropDownOpen && "open")}>
        <li>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${site.siteMetadata.siteUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
            <Icon name="facebook" />
          </a>
        </li>
        <li>
          <a
            href={`https://twitter.com/intent/tweet?text=${site.siteMetadata.twitterText}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
            <Icon name="twitter" />
          </a>
        </li>
        <li>
          <a
            href={`mailto:?subject=${site.siteMetadata.emailSubject}&body=${site.siteMetadata.emailBody}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Email
            <Icon name="email" />
          </a>
        </li>
        {/*<li>*/}
        {/*  <a*/}
        {/*    href="https://www.linkedin.com/company/cvs-pharmacy/"*/}
        {/*    target="_blank"*/}
        {/*    rel="noopener noreferrer"*/}
        {/*  >*/}
        {/*    LinkedIn*/}
        {/*    <Icon name="linkedin" />*/}
        {/*  </a>*/}
        {/*</li>*/}
      </ul>
    </div>
  )
}

ShareDropdown.defaultProps = {
  partner: null,
  menuLinks: null,
}

ShareDropdown.propTypes = {
  partner: PropTypes.string,
  menuLinks: PropTypes.string,
}

export default ShareDropdown
