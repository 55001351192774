import RandomString from "randomstring"
import Cookie from "js-cookie"
import { navigate } from "gatsby"

const config = require("../../gatsby-config")

let error = false

export const isBrowser = typeof window !== "undefined"

export const login = () => {
  if (!isBrowser) {
    return
  }

  const state = RandomString.generate()

  Cookie.set("oauth", { state, returnPath: window.location.pathname })

  const endpoint = config.siteMetadata.oauthAuthEndpoint

  const params = {
    scope: "openid",
    client_id: config.siteMetadata.oauthClientId,
    state: state,
    response_type: "code",
    redirect_uri: `${window.location.protocol}//${window.location.hostname}/app/oauth2/callback`,
  }

  const queryString = Object.keys(params)
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join("&")

  window.location = `${endpoint}?${queryString}`
}

export const logout = () => {
  Cookie.remove("token")

  navigate("/")
}

export const handleAuthentication = () => {
  if (!isBrowser) {
    return
  }

  const cookie = Cookie.getJSON("oauth")
  Cookie.remove("oauth")

  if (!cookie) {
    error = "An error occurred while attempting to authenticate you."
    return navigate("/")
  }

  const { state, returnPath } = cookie
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  if (urlParams.has("error_description")) {
    error = urlParams.get("error_description")
  } else if (
    urlParams.has("state") &&
    urlParams.get("state") === state &&
    urlParams.has("code")
  ) {
    Cookie.set("token", urlParams.get("code"), { expires: 1 })
    /* FOR QA. REMOVE AFTER TESTING */
  } else if (
    urlParams.has("state") &&
    urlParams.get("state") === "tjtest" &&
    urlParams.has("code")
  ) {
    Cookie.set("token", urlParams.get("code"), { expires: 1 })
    /* END QA BLOCK */
  } else {
    error = "We were unable to validate your request."
  }

  if (returnPath) {
    return navigate(returnPath)
  }

  return navigate("/")
}

export const isAuthenticated = () => isBrowser && Cookie.get("token")

export const getError = () => error
