import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import "./styles.scss"

const Section = ({ className, id, children }) => {
  const getClassNames = () => classNames("section", className)

  return <section id={id} className={getClassNames()}>{children}</section>
};

Section.defaultProps = {
  className: null,
  id: null,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
};

export default Section
