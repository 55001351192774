import React from "react"
import "./styles.scss"
import classNames from "classnames"

const Container = ({ children, className }) => (
  <div className={classNames("container", className)}>
    {children}
  </div>
);

export default Container;
