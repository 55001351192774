import React from "react"
import { isAuthenticated } from "../utils/auth"

const { Provider, Consumer } = React.createContext(false);

const AuthProvider = ({ children }) => (
  <Provider value={isAuthenticated()}>{children}</Provider>
);

export { AuthProvider, Consumer as AuthConsumer }
