import React from "react"
import * as Icons from "./icons"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./styles.scss"

const Icon = ({ name, inline, className }) => {
  const IconComponent = Icons[name]

  const getClassnames = () =>
    classNames("icon", `icon--${name}`, inline && "icon--inline", `${className}`)

  return <IconComponent className={getClassnames()} />
}

Icon.defaultProps = {
  inline: false,
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
}

export default Icon
