import { Container } from "../Grid"
import ShareDropdown from "../ShareDropdown"
import Button from "../Button"
import Icon from "../Icon"
import Menu from "../Menu"
import { Link } from "gatsby"
import HamburgerIcon from "../Hamburger"
import { /* login, logout, */ getError } from "../../utils/auth"
// import { AuthConsumer } from "../../context/AuthContext"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { throttle } from "lodash"
import React from "react"
import classNames from "classnames"
import DoveLogo from "../../images/theme/logo-dove-horizontal.svg"
import DiscoveryLogo from "../../images/global/img-de-logo.png"
import AmazingMe from "../../images/theme/logo-amazing-me.svg"
import "./styles.scss"

class Header extends React.Component {
  state = {
    menuOpen: false,
    stickyHeader: 0,
    hiddenHeader: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleHamburgerClick = () => {
    this.setState(state => ({
      menuOpen: !state.menuOpen,
    }))
  }

  headerClasses = () => {
    const { stickyHeader, hiddenHeader } = this.state
    return classNames("header", {
      "header--sticky": stickyHeader > 15,
      "header--hidden": stickyHeader > 250 && hiddenHeader,
    })
  }

  handleScroll = throttle(() => {
    const { stickyHeader } = this.state
    const clientHeight = document.body.clientHeight
    const isBigEnough = clientHeight - window.innerHeight > 200
    if (!isBigEnough) {
      return this.setState({
        stickyHeader: 0,
        hiddenHeader: false,
      })
    }

    // controls the layout__sidebar--sticky class
    // also checks to see if we're scrolling up or not, and hides the nav if we are scrolling up.
    return this.setState({
      stickyHeader: isBigEnough ? window.pageYOffset : 0,
      hiddenHeader: isBigEnough && window.pageYOffset - stickyHeader > 0,
    })
  }, 250)

  render() {
    const { data, location } = this.props
    const { menuOpen } = this.state
    const error = getError()

    return (
      // <AuthConsumer>
      //   {isAuthenticated => (
      <header className={this.headerClasses()}>
        <Container>
          <div className="header__logos">
            <div className="header__logos-branding">
              <div className="header__logos-branding--top">
                {location !== "home" && (
                  <Link
                    to="/"
                    className="header__logo amazing-me-header__logo header__logo"
                  >
                    <img src={AmazingMe} alt={"Amazing Me"} />
                  </Link>
                )}
                {location === "home" && (
                  <div>
                    <Link
                      to="/"
                      className="amazing-me-header__logo--home header__logo"
                    >
                      <img src={AmazingMe} alt={"Amazing Me"} />
                    </Link>
                  </div>
                )}
                <Link
                  to="/"
                  // target="_blank"
                  rel="noopener noreferrer"
                  className="header__logo dove__logo Self Esteem Logo disappearing-logo"
                >
                  <img
                    src={DoveLogo}
                    alt={data.site.siteMetadata.partner}
                    className="self-esteem-header__logo"
                  />
                </Link>
                <Link
                  to="/"
                  className="header__logo de__logo disappearing-logo"
                >
                  <img
                    src={DiscoveryLogo}
                    alt={data.site.siteMetadata.partner}
                  />
                </Link>
              </div>
            </div>
            {/*<Link to="/" className="dose__knowledge">*/}
            {/*  /!*<h2 className="campaign__title"></h2>*!/*/}
            {/*</Link>*/}
          </div>
          <div
            className={classNames(
              "header-links",
              menuOpen && "header-links--open"
            )}
          >
            <div className="utility__links">
              <div className="header__signup">
                <Button
                  className="header__signup-button"
                  link
                  to="https://info.discoveryeducation.com/AmazingMe.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="signup" inline />
                  Want to hear more from us?
                </Button>
              </div>
              <div className="header__share">
                <ShareDropdown />
              </div>

              {/*<div className="header__signin">*/}
              {/*  <button onClick={isAuthenticated ? logout : login}>*/}
              {/*    CVS Health Employee Sign {isAuthenticated ? "Out" : "In"}*/}
              {/*  </button>*/}
              {/*</div>*/}
            </div>
            <nav className="primary__nav-container">
              <Menu />
            </nav>
          </div>{" "}
          <HamburgerIcon
            onClick={this.handleHamburgerClick}
            isActive={menuOpen}
          />
        </Container>
        {error && (
          <Container>
            <div className="header__error">{error}</div>
          </Container>
        )}
      </header>
      //   )}
      // </AuthConsumer>
    )
  }
}

Header.propTypes = {
  data: PropTypes.object.isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            partner
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
