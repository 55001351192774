import { useStaticQuery, graphql } from 'gatsby';

const useImages = () => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      images: allFile(
        filter: { extension: { regex: "/(gif)|(jpg)|(png)|(jpeg)|(svg)/" } }
      ) {
        edges {
          node {
            relativePath
            name
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const findImageNodeByPath = (filepath) => {
    const matchingEdge = data.images.edges.find((edge) => {
      // Replace used to remove leading slash if provided...
      return (
        filepath.replace(/^\//, '') ===
        edge.node.relativePath.replace(/^\//, '')
      );
    });
    return matchingEdge ? matchingEdge.node : null;
  };

  const findImageNodeByFilename = (filename) => {
    const matchingEdge = data.images.edges.find((edge) => {
      return filename === `${edge.node.name}.${edge.node.extension}`;
    });
    return matchingEdge ? matchingEdge.node : null;
  };

  const getFinalImagePathByFilename = (filename) => {
    const node = findImageNodeByFilename(filename);
    return node ? node.publicURL : null;
  };

  const getFinalImagePath = (filepath) => {
    const node = findImageNodeByPath(filepath);
    return node ? node.publicURL : null;
  };

  return {
    images: data.images,
    findImageNodeByPath,
    findImageNodeByFilename,
    getFinalImagePathByFilename,
    getFinalImagePath,
  };
};

export default useImages;
