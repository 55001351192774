import PropTypes from "prop-types"
import React from "react"
import Icon from "../Icon"

const OutboundLink = ({ children, to, withIcon }) => {
  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      {withIcon && <Icon name="externallink" inline />}
      {children}
    </a>
  )
}

OutboundLink.defaultProps = {
  withIcon: false,
}

OutboundLink.propTypes = {
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  withIcon: PropTypes.bool,
}

export default OutboundLink
